import { HttpClient } from '@wix/http-client';
import { createRequest } from '@wix/communities-blog-client-common';

const createRequestWithHttpClient = (args) => {
  const httpClient = {
    client: new HttpClient(),
    cancelTokenSource: HttpClient.CancelToken.source,
    isCancel: HttpClient.isCancel,
  };
  return createRequest({ ...args, httpClient });
};

let instance;
const getInstance = () => instance;

export const setInstance = (newInstance) => (instance = newInstance);
export default createRequestWithHttpClient({ getInstance });
